import { Outlet } from '@remix-run/react';

import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';

export function Component() {
  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Outlet />
    </ProvidersList>
  );
}
